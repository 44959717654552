import './style.css';
import './cursor.css'
import './cc.js';

import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';



const ex_3d_obj = './3d/gm/monogram-girish-mehta-3d.obj';


const textureLoader = new THREE.TextureLoader()
const matcapTexture = textureLoader.load('/textures/matcaps/1.png')
const mouse_coord = { x: 0, y: 0 };
const scene = new THREE.Scene();

let startTime = Date.now(),
    obj_3d_rotation = { x: 0, y: 0, z: 0 },
    gyro_bool = false;

// Sizes & aspect
const screenSize_offset = 30;

const sizes = {
    width: window.innerWidth - screenSize_offset,
    height: window.innerHeight - screenSize_offset,
}
let screen_aspect = sizes.width / sizes.height;

// Camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height)
camera.position.z = 16
    ;
scene.add(camera);


// Renderer
const renderer = new THREE.WebGLRenderer({
    canvas: document.querySelector('canvas.webgl')
})
renderer.setSize(sizes.width, sizes.height);
renderer.setClearColor(0x0e0e0e, 0);
renderer.setPixelRatio(window.devicePixelRatio);
renderer.render(scene, camera);



// Materials
const matcap_material = new THREE.MeshMatcapMaterial();
matcap_material.matcap = matcapTexture;

// Geometries & Meshes

// Monogram 3D
let monogram3d_obj;

// manager
const manager = new THREE.LoadingManager(loadModel);

const loader = new OBJLoader(manager);
loader.load(ex_3d_obj, function (obj) {
    monogram3d_obj = obj;
}, onProgress, onError);

function loadModel() {
    monogram3d_obj.traverse(function (child) {
        if (child instanceof THREE.Mesh) {
            child.material = matcap_material;
        }
    });
    monogram3d_obj.rotation.y = 0;

    if (sizes.width < sizes.height) {
        monogram3d_obj.scale.set(0.8, 0.8, 0.8);
    }


    scene.add(monogram3d_obj);
}

function onProgress(xhr) {
    if (xhr.lengthComputable) {
        const percentComplete = xhr.loaded / xhr.total * 100;
    }
}
function onError() { }


//Events

document.addEventListener('mousemove', onDocumentMouseMove, false);
window.addEventListener('resize', onWindowResize, false);
onWindowResize();

function onDocumentMouseMove(event) {
    event.preventDefault();
    let windowHalfX = window.innerWidth / 2,
        windowHalfY = window.innerHeight / 2;

    mouse_coord.x = (event.clientX - windowHalfX) / windowHalfX;
    mouse_coord.y = (event.clientY - windowHalfY) / windowHalfY;
}

function onWindowResize() {

    sizes.width = window.innerWidth - screenSize_offset;
    sizes.height = window.innerHeight - screenSize_offset;
    screen_aspect = sizes.width / sizes.height;


    renderer.setSize(sizes.width, sizes.height);
    camera.aspect = screen_aspect;
    camera.updateProjectionMatrix();


    if (sizes.width < sizes.height && monogram3d_obj !== undefined) {
        monogram3d_obj.scale.set(0.8, 0.8, 0.8);
    }
}


/**
 * Animate
 */
const render = () => {
    let currentTime = Date.now();
    // Update objects

    if (monogram3d_obj !== undefined) {
        monogram3d_obj.rotation.y = ((mouse_coord.x * (Math.PI * 0.1)));
        monogram3d_obj.position.y = 0.2 * Math.cos(currentTime / 1500);
    }

    // Render
    renderer.render(scene, camera);

    // Call tick again on the next frame
    window.requestAnimationFrame(render);
}

render();

// ----------------

